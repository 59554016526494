import React, { Component } from "react";
import Layout from "../components/layout";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { Breadcrumbs } from "../components/styleguide";
import Form from "../components/form";
import MarkdownRenderer from "react-markdown-renderer";
import Helmet from "react-helmet";

const MapWithAMarker = withScriptjs(
  withGoogleMap(({ center, zoom }) => (
    <GoogleMap
      defaultZoom={zoom}
      defaultCenter={center}
      defaultOptions={{ mapTypeControl: false, fullscreenControl: false }}>
      <Marker position={center} />
    </GoogleMap>
  ))
);

class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 50.0134759,
      lng: 20.076221,
    },
    zoom: 15,
  };
  render() {
    const { data, common, breadcrumbs } = this.props.pageContext;
    const [translations] = common.translations;
    const [static_data] = common.static_data;
    const { small_image, big_image } = data;
    const grid = (
      <div className="rotated-grid">
        <div className="grid-row fs">
          {/* <div className="grid-item empty"></div> */}
          <div className="grid-item">
            <div className="item-content">
              <img
                className="lazyload"
                data-src={require("../images/contact/home.svg")}
                alt="contact"
              />
              <h3>
                <strong>{translations.office}</strong>
              </h3>
              <a href={`tel:${data.phone}`} className="contact-row">
                <img
                  className="lazyload"
                  data-src={require("../images/icons/phone.svg")}
                  alt="Phone"
                />
                <span>{data.phone}</span>
              </a>
              <a href={`tel:${data.tel}`} className="contact-row">
                <span>{data.tel}</span>
              </a>
              <a href={`tel:${data.fax}`} className="contact-row">
                <img
                  className="lazyload"
                  data-src={require("../images/icons/fax.svg")}
                  alt="Fax"
                />
                <span>{data.fax}</span>
              </a>
              <a href={`mailto:${data.mail}`} className="contact-row">
                <img
                  className="lazyload"
                  data-src={require("../images/icons/email.svg")}
                  alt="Mail"
                />
                <span>{data.mail}</span>
              </a>
            </div>
          </div>
        </div>
        <div className="grid-row fs">
          {/* <div className="grid-item empty"></div> */}
          <div className="grid-item empty"></div>
          <div className="grid-item">
            <div className="item-content address">
              <img
                className="lazyload"
                data-src={require("../images/contact/target.svg")}
                alt=""
              />
              <MarkdownRenderer markdown={data.address} />
            </div>
          </div>
        </div>
        <div className="grid-row fs">
          <div className="grid-item hours">
            <div className="item-content">
              <img
                className="lazyload"
                data-src={require("../images/contact/clock.svg")}
                alt=""
              />
              <MarkdownRenderer markdown={data.opening_hours} />
            </div>
          </div>
        </div>
        <div className="grid-row fs">
          <div className="grid-item social">
            <div className="item-content">
              <h3>
                <MarkdownRenderer markdown={data.social_media} />
              </h3>
              <div className="social-links">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={static_data.linkedin}>
                  <img
                    className="lazyload"
                    data-src={require("../images/icons/linkedin.svg")}
                    alt="Linkedin"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-row fs mobile-none">
          <div className="grid-item empty" />
          <div className="grid-item bg">
            <div
              className="lazyload bg-item"
              style={{
                backgroundImage: small_image
                  ? `url('${small_image}')`
                  : `url('${require("../images/contact/grid-image-sm.png")}')`,
              }}
            />
          </div>
          <div className="grid-item bg lg">
            <div
              className="lazyload bg-item"
              style={{
                backgroundImage: big_image
                  ? `url('${big_image}')`
                  : `url('${require("../images/contact/grid-image-lg.png")}')`,
              }}
            />
          </div>
        </div>
      </div>
    );
    return (
      <Layout
        data={common}
        title={data.seoTitle}
        description={data.seoDescription}
        hrefLang={data.hrefLang}>
        <Helmet>
          <body className="bg-contact" />
        </Helmet>
        <div className="main-container">
          <Breadcrumbs data={breadcrumbs} lang={common.lang} />
        </div>
        <section className="contact-grid">
          <div className="main-container">
            <header className="section-header">
              <h1>
                <MarkdownRenderer markdown={data.title} />
              </h1>
            </header>
          </div>
          <div className="main-container">{grid}</div>
        </section>
        <div className="main-container">
          <section className="contact-form">
            <header className="section-header">
              <h2>
                <MarkdownRenderer markdown={data.form_title} />
              </h2>
            </header>
            <Form lang={common.lang} data={data} translations={translations} />
          </section>
        </div>
        <section className="contact-map">
          <MapWithAMarker
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBNN7q9I8K39ENJYhv5_YQMTQoU796iNwY&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `500px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            center={this.props.center}
            zoom={this.props.zoom}
          />
        </section>
      </Layout>
    );
  }
}

export default Contact;
