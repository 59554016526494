import React, { Component } from "react";
import { Button, Input, Checkbox, Textarea } from "./styleguide";
import MarkdownRenderer from "react-markdown-renderer";

class Form extends Component {
  state = {
    formStatus: {
      errors: {},
      succes: {},
    },
    agree1: false,
    agree2: false,
    phonePrefix: "",
    showErrorInfo: false,
    form: {
      name: "",
      company: "",
      mail: "",
      tel: "",
      msg: "",
    },
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, [e.target.id]: e.target.value },
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { mail } = e.target;
    let { formStatus, agree1, agree2, form } = this.state;
    if (!mail.checkValidity()) {
      formStatus.errors.mail = true;
    }
    if (!agree1) {
      formStatus.errors.agree1 = true;
    }
    if (!agree2) {
      formStatus.errors.agree2 = true;
    }
    this.setState({ formStatus });

    if (!Object.values(formStatus.errors).includes(true)) {
      this.setState({ showErrorInfo: false });
      e.target.submit();
    } else {
      this.setState({ showErrorInfo: true });
    }
  };

  checkInput = ({ value }, type) => {
    const regex_mail = new RegExp(/^\S+@\S+\.[A-Za-z]+$/);
    const { formStatus } = this.state;
    switch (type) {
      case "mail":
        if (value.match(regex_mail)) {
          formStatus.errors[type] = false;
          formStatus.succes[type] = true;
          return this.setState({ formStatus });
        } else {
          formStatus.errors[type] = true;
          formStatus.succes[type] = false;
          return this.setState({ formStatus });
        }
      default:
        return;
    }
  };

  checkboxChange = ({ target }) => {
    const { formStatus } = this.state;
    this.setState({ [target.id]: !this.state[target.id] });
    if (target.checked) {
      formStatus.errors[target.id] = false;
      this.setState({ formStatus });
    }
  };

  render() {
    const {
      formStatus: { errors, succes },
      agree1,
      agree2,
      showErrorInfo,
      phonePrefix,
      form: {},
    } = this.state;

    const { data, translations } = this.props;

    const getThanksUrl = () => {
      switch (this.props.lang) {
        case "pl":
          return "/pl/dziekujemy";
        case "en":
          return "/en/thank-you";
        default:
          return "/pl/dziekujemy";
      }
    };

    const getFormName = () => {
      switch (this.props.lang) {
        case "pl":
          return "formularz_kontaktowy";
        case "en":
          return "contact_form";
        default:
          return "contact_form";
      }
    };

    return (
      <form
        name={getFormName()}
        onSubmit={this.onSubmit}
        noValidate
        method="POST"
        action={getThanksUrl()}
        data-netlify="true">
        <input type="hidden" name="form-name" value={getFormName()} />
        <div className="form-box">
          <Input
            type="text"
            id="name"
            name="name"
            onChange={this.handleChange}
            label={translations.name_surname}
            placeholder={translations.name_surname}
            error={errors.name || false}
            succes={succes.name || false}
            required
          />
          <Input
            type="text"
            name="company"
            id="company"
            onChange={this.handleChange}
            onBlur={(value) => this.checkInput(value, "company")}
            label={translations.company}
            placeholder={translations.company}
            error={errors.company || false}
            succes={succes.company || false}
          />
          <div className="row-group">
            <Input
              type="text"
              name="mail"
              id="mail"
              onChange={this.handleChange}
              label={translations.mail_address}
              placeholder={translations.mail_address}
              onBlur={(value) => this.checkInput(value, "mail")}
              error={errors.mail || false}
              succes={succes.mail || false}
              required
            />
            <Input
              type="text"
              name="tel"
              id="tel"
              onChange={this.handleChange}
              value={phonePrefix}
              label={translations.telephone}
              placeholder={translations.telephone}
              onFocus={() => this.setState({ phonePrefix: "+48" })}
              error={errors.tel || false}
              succes={succes.tel || false}
            />
          </div>
          <Textarea
            name="msg"
            label={translations.message}
            id="msg"
            onChange={this.handleChange}
          />
          <div className="form-action">
            <div className="check-groups">
              <Checkbox
                id="agree2"
                label={<MarkdownRenderer markdown={data.agree2} />}
                form
                error={errors.agree2 || false}
                onChange={(e) => this.checkboxChange(e)}
                checked={agree2}
              />
              <Checkbox
                id="agree1"
                label={data.agree1}
                form
                error={errors.agree1 || false}
                onChange={(e) => this.checkboxChange(e)}
                checked={agree1}
              />
            </div>
            <div className="action-button">
              <Button type="submit" content={translations.send_message} fill />
            </div>
          </div>
          {showErrorInfo && (
            <div className="form-errors">
              <img
                className="lazyload"
                data-src={require("../images/icons/error-icon.svg")}
                alt="error-icon"
              />
              <span>{data.form_error}</span>
            </div>
          )}
        </div>
      </form>
    );
  }
}

export default Form;
